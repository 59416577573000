import "./App.css";
import axios from "axios";
// import image from "./image/Evolution.png";
import image from "./image/Evolution2.png";

import { Button, Form, Input, Radio, message } from "antd";
import { useState, useEffect } from "react";
// const URL = "https://api.cash-5656.com";
const URL = "https://mainapi.enjoy-7979.com.com";

var popupWindow1: any = null;

const API = axios.create({
  baseURL: URL,
});
function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const [username, setUserName] = useState("");
  const [totalBalance, setTotalBalance] = useState(0);
  const [amount, setAmount] = useState(0);

  const handleDeposit = async () => {
    try {
      if (!username) {
        messageApi.error("이름을 입력하세요!");
        return;
      }

      await API.post("/transaction-evolution", {
        username,
        type: "0",
        amount: Number(amount),
      });
      messageApi.success("Success!");
    } catch (error) {
      messageApi.error("잔액이 부족하거나 이름이 잘못됨!");
    }
  };
  const handleWitdraw = async () => {
    try {
      if (!username) {
        messageApi.error("이름을 입력하세요!");
        return;
      }

      await API.post("/transaction-evolution", {
        username,
        type: "1",
        amount: Number(amount),
      });
      messageApi.success("성공적인 거래 !");
    } catch (error) {
      messageApi.error("잔액이 부족하거나 이름이 잘못됨!");
    }
  };
  const Create = async (params) => {
    try {
      const { username } = params;
      await API.post("/create-evolution", {
        username,
      });
      messageApi.success("성공을 창조하세요!");
    } catch (error) {
      messageApi.error("중복된 아이디가 있습니다!");
    }
  };
  function popupwindow(url: any, title: any, w: any, h: any) {
    // eslint-disable-next-line no-restricted-globals
    var left = screen.width / 2 - w / 2;
    // eslint-disable-next-line no-restricted-globals
    var top = screen.height / 2 - h / 2;

    if (!popupWindow1 || popupWindow1.closed) {
        popupWindow1 = window.open(
            url,
            title,
            'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
                w +
                ', height=' +
                h +
                ', top=' +
                top +
                ', left=' +
                left
        );
    } else {
        alert('게임을 종료 후에 입장해 주세요');
        return;
    }

    var checkPopupStatus = setInterval(function () {
        // If the popup window is closed or undefined, clear the interval
        if (!popupWindow1 || popupWindow1.closed) {
            clearInterval(checkPopupStatus);
            popupWindow1 = null;
            console.log('Popup window closed');
        }
    }, 1000); // Check every second
}

  const launchingGame = async (value) => {
    try {
      const { data } = await API.post("/launching-evolution", {
        username: value.username,
        password: value.password
      });
      popupwindow(data, 'newwindow' , 800, 600);

      // window.open(data, "toolbar=0,location=0,menubar=0");
    } catch (error) {
      messageApi.error("3초 후에 다시 시도해 주세요. !");
    }
  };
  const getTotalBalance = async () => {
    try {
      const { data } = await API.post("/getotalbalance");
      setTotalBalance(data.balance);
    } catch (error) {}
  };
  useEffect(() => {
    getTotalBalance();
  }, []);
  return (
    <div className="App">
      {contextHolder}
      <div className="">
        <img src={image} style={{borderRadius: 20}} width={200} alt="" />
      </div>
      <div className="main">
        {/* <div className="main_header" style={{
          display: 'flex',
          gap: 10,
          fontSize: 20
        }}>
          <strong>총보유금 : </strong>
          {Number(totalBalance.toFixed(0)).toLocaleString("en-US")}
        </div>
        <Form name="basic" className="form_main"  onFinish={Create}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input
              style={{
                width: 250,
              }}
              size="large"
              placeholder="아이디를입력하세요 "
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 4,
              // span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" size="large">
              아이디생성
            </Button>
          </Form.Item>
        </Form>
        <Form name="basic" className="form_main" style={{
          alignItems: 'start',
          flexDirection: 'column'
        }}>
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input
            type="number"
              style={{
                width: 250,
              }}
              size="large"
              placeholder="금액을입력하세요"
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              gap: 10
            }}
          >
            <Button type="primary" onClick={() => handleDeposit()} style={{
              marginRight: 30
            }}>
              입금
            </Button>
            <Button type="primary"  onClick={() => handleWitdraw()} >
            출금
            </Button>
          </Form.Item>
        </Form> */}

        <Form name="basic" className="form_main" onFinish={launchingGame}>
          <div className>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                style={{
                  width: 250,
                }}
                size="large"
                placeholder="아이디를입력하세요"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                style={{
                  width: 250,
                }}
                size="large"
                placeholder="비밀번호를 입력하세요"
              />
            </Form.Item>
          </div>

          <Form.Item
            wrapperCol={{
              offset: 4,
              // span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" size="large" style={{
              height: 104
            }}>
              게임입장
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default App;
